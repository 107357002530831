import Vue from 'vue'
import App from './App.vue'
import i18n from './main/i18n'
import router from './router'
import store from './store'
import VueCompositionApi from '@vue/composition-api'
import BootstrapVue, {IconsPlugin} from "bootstrap-vue"

// import 'modern-normalize/modern-normalize.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import './style/merchant.less'

import VueMeta from 'vue-meta'

Vue.use(VueMeta)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// import 'bootstrap/dist/css/bootstrap.min.css'

Vue.use(VueCompositionApi)
Vue.config.productionTip = false
//
// const app = createApp(App, {
//   i18n,
//   router,
//   store,
// })
//
// app.use(i18n)
// app.use(router)
// app.use(store)

// app.mount('#app')

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
