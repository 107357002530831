
export default class ModelErrors {
    errors: any = {}

    constructor(errors?: any) {
      this.setErrors(errors)
    }

    setErrors(errors?: Record<string, string>): any {
      this.errors = errors ? ({ ...errors }) : {}
      return this
    }

    hasErrorsSplit(attribute: string, split = '|'): boolean {
      const attributes = attribute.split(split)
      for (const attr of attributes) {
        if (this.has(attr)) return true
      }
      return false
    }

    getErrorSplit(attribute: string, split = '|'): boolean | string {
      const attributes = attribute.split(split)
      let message
      for (const attr of attributes) {
        message = this.get(attr)
        if (message) return message
      }
      return false
    }

    has(attribute?: string): boolean {
      return Boolean((attribute && (attribute in this.errors) &&
          this.errors[attribute]) || (!attribute && (Object.keys(this.errors).length > 0)))
    }

    getMessage(attribute: string): string {
      if (!this.has(attribute)) return ''
      const res = this.errors[attribute]
      return Array.isArray(res) ? res[0] : res
    }

    get(attribute: string): string {
      return this.getMessage(attribute)
    }

    set(attribute: string, error: string): void {
      this.errors = { ...this.errors, [attribute]: error }
      // Vue.set(this.errors, attribute, error);
    }

    clear(attribute?: string): void {
      if (!attribute) this.setErrors()
      else {
        if (this.has(attribute)) {
          delete this.errors[attribute]
        }
      }
    }

    getFirstErrorAttribute(): string {
      const keys = Object.keys(this.errors)
      return keys.length === 0 ? '' : keys[0]
    }

    getFirstError(): string {
      if (!this.has()) return ''
      return this.errors[this.getFirstErrorAttribute()]
    }

    bootstrapInputState(attribute: string) {
      return this.hasErrorsSplit(attribute) ? false : null
    }
}
