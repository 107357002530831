import {
  MoneyFormat, MoneyFormatBackoffice, MoneyFormatSideRates, MoneyFormatUpdated,
} from '@/models/MoneyFormat';
import { CurrencyCode } from '@/models/finance';

export function MoneyFormatFilter(amount: number|string, currency: CurrencyCode): string {
  return MoneyFormat.format(amount, currency);
}

export function MoneyFormatFilterUpdated(amount: number|string, currency: CurrencyCode): string {
  return (new MoneyFormatUpdated()).format(amount, currency);
}

export function MoneyFormatFilterSideRates(amount: number|string, currency: CurrencyCode): string {
  return (new MoneyFormatSideRates()).formatToFloor(amount, currency);
}

export function MoneyFormatFilterBackoffice(amount: number|string, currency: CurrencyCode): string {
  return (new MoneyFormatBackoffice()).format(amount, currency);
}
