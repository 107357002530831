
import Vue from 'vue'
import {Component} from 'vue-property-decorator'

@Component({
  name: "MerchantLayout"
})
export default class MerchantLayout extends Vue {

}
