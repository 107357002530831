export class ApplicationLocale {
    private static instance: ApplicationLocale;

    private constructor() {
      // constructor
    }

    public static getInstance(): ApplicationLocale {
      if (!ApplicationLocale.instance) {
        ApplicationLocale.instance = new ApplicationLocale();
      }
      return ApplicationLocale.instance;
    }

    private locales = ['ru', 'en'];

    get locale(): string {
      const val = localStorage.lang;
      return this.locales.includes(val) ? val : 'ru';
    }

    set locale(value: string) {
      localStorage.lang = this.locales.includes(value) ? value : 'ru';
    }
}
