import axios from "axios";

export interface SimpleUserProfileInterface {
  username: string
  email: string
}

export class SimpleUserProfile implements SimpleUserProfileInterface {
  username!: string
  email: string
}

export class CoreState {
  profile: SimpleUserProfile | null = null;

  login(profile: SimpleUserProfile) {
    this.profile = profile;
    localStorage.setItem('simpletoken', profile.username);
  }

  logout() {
    this.profile = null;
    localStorage.removeItem('simpletoken')
  }

  getProfile(): SimpleUserProfile | null {
    return this.profile;
  }

  isLogged(): boolean {
    return Boolean(this.profile) && Boolean(localStorage.getItem('simpletoken'));
  }

  async checkLogged() {
    const username = localStorage.getItem('simpletoken')

    if (!username && !this.profile) {
      return false
    }

    const profile = await axios.post('/webapi/auth/loginsimple', {
      call: 'checkLogged',
      m: {
        username: this.profile ? this.profile.username : username
      }
    }).then(result => {
      return Object.assign(new SimpleUserProfile(), result.data.profile);
    })

    return profile
  }
}
