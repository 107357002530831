import Vue from 'vue'
import Vuex from 'vuex'
// import {AxiosWebApiClient} from '@/models/core/webapi/WebApiClient';
import {CoreState} from '@/models/core/CoreState'

Vue.use(Vuex)

const Store = new Vuex.Store({
  state: {
    locale: 'ru',
    // apiClient: new AxiosWebApiClient(`${process.env.VUE_APP_API_HOST}`),
    user: new CoreState(),
  },
  mutations: {
    changeLocale(state, locale = 'ru') {
      state.locale = locale
    },
    logout(state) {
      state.user.logout();
    }
  },
  actions: {
    logout({commit}) {
      console.log('store action commit logout');
      commit('logout')
    }
  },
  modules: {},
})

export default Store
