import WebFormModel from "@/models/core/form/WebFormModel";
import axios from "axios";

export interface TxInfo {
  usdtAddress: string | null
  rurAmount: number
  usdtAmount: number | null
  tx: string | null
  paid: boolean
  rate: string | number | null
  paymentUrl: string | null
}

export class TxStatusModel extends WebFormModel {

  loadStatus(merchantId, orderNumber) {
    return axios.post('/webapi/merchants/cardtransfer', {
      call: 'status',
      m: {
        m: merchantId,
        o: orderNumber
      }
    }).then(result => {
      return result.data.txInfo as TxInfo
    }).catch(err => {
      this.checkAxiosError(err)
      throw err
    })
  }

}
