import numbro from 'numbro';
import * as NumbroRuLang from 'numbro/languages/ru-RU';
import { CurrencyCode } from '@/models/finance'

numbro.registerLanguage(NumbroRuLang);
numbro.setLanguage('ru-RU');

export class MoneyFormat {
  static _getNumeralFormat(currency = CurrencyCode.USD): string {
    let result = '0,0.00';

    switch (currency) {
      case CurrencyCode.BTC:
        result = '0,0.00000000';
        break;
      case CurrencyCode.ETH:
        result = '0,0.00000000';
        break;
      case CurrencyCode.USDT:
      case CurrencyCode.USDTt:
        result = '0,0.000000'; // в теории там 6 знаков после запятой
        break;
    }

    return result;
  }

  static format(amount: string|number, currency = CurrencyCode.USD): string {
    return this.formatSmartZeroes(amount, currency);
  }

  static formatSmartZeroes(amount: string|number, currency = CurrencyCode.USD,
    zeroShow = true, zeroCustomFormat?: string): string {
    let str = `${amount}`;
    str = str.replace(/\s/, '');
    str = str.replace(/,/, '.');
    const val = parseFloat(str);
    let format = this._getNumeralFormat(currency);

    if (val == 0) {
      if (!zeroShow) return '';

      if (zeroCustomFormat) format = zeroCustomFormat;
    }

    return numbro(val).format(format).replace(/([.,]\d{2,}?)[0]+$/, '$1');
  }
}

require('./polyfill/math.js');

export class MoneyFormatUpdated {
  getNumeralFormat(currency = CurrencyCode.USD): string | Record<string, any> {
    let result = {
      thousandSeparated: true,
      mantissa: 2,
    };

    switch (currency) {
      // case 'BCC':
      case CurrencyCode.BTC:
        // case 'BTG':
        result = {
          thousandSeparated: true,
          mantissa: 8,
        };
        break;
      case CurrencyCode.ETH:
        result = {
          thousandSeparated: true,
          mantissa: 8,
        };
        break;
      case CurrencyCode.USDT:
      case CurrencyCode.USDTt:
        result = {
          thousandSeparated: true,
          mantissa: 6,
        };
        break;
    }

    // return {
    //     thousandSeparated: true,
    //         mantissa: 6
    // };

    return result;
  }

  format(amount: string|number, currency = CurrencyCode.USD): string {
    return numbro(amount).format(this.getNumeralFormat(currency));
  }

  formatToFloor(amount: string|number, currency = CurrencyCode.USD): string {
    const format: any = this.getNumeralFormat(currency);
    const mantissa = format.mantissa ? format.mantissa : 2;

    const amnt = Math.floor10(parseFloat(amount as string), -mantissa);

    return numbro(amnt).format(format);
  }
}

export class MoneyFormatSideRates extends MoneyFormatUpdated {
  formatToFloor(amount: string|number, currency = CurrencyCode.USD): string {
    const format: any = this.getNumeralFormat(currency);
    const mantissa = format.mantissa ? format.mantissa : 2;

    const amnt = Math.floor10(parseFloat(amount as string), -mantissa);

    return numbro(amnt).format(format);
  }

  getNumeralFormat(currency = CurrencyCode.USD): string | Record<string, any> {
    let result = {
      thousandSeparated: true,
      mantissa: 3,
    };

    switch (currency) {
      case 'CRYPTOSIDE' as CurrencyCode:
        result = {
          thousandSeparated: true,
          mantissa: 2, // 8
        };
        break;
      case CurrencyCode.BTC:
        result = {
          thousandSeparated: true,
          mantissa: 2, // 8
        };
        break;
      case CurrencyCode.RUR:
        result = {
          thousandSeparated: true,
          mantissa: 2, // 8
        };
        break;
      case CurrencyCode.INR:
        result = {
          thousandSeparated: true,
          mantissa: 2, // 8
        };
        break;
      case CurrencyCode.ETH:
        result = {
          thousandSeparated: true,
          mantissa: 2, // 6
        };
        break;
      case CurrencyCode.USDTt:
      case CurrencyCode.USDT:
        result = {
          thousandSeparated: true,
          mantissa: 2,
        };
        break;
    }

    return result;
  }
}

export class MoneyFormatBackoffice extends MoneyFormatUpdated {
  formatToFloor(amount: string|number, currency = CurrencyCode.USD): string {
    const format: any = this.getNumeralFormat(currency);
    const mantissa = format.mantissa ? format.mantissa : 2;

    const amnt = Math.floor10(parseFloat(amount as string), -mantissa);

    return numbro(amnt).format(format);
  }

  getNumeralFormat(currency = CurrencyCode.USD): string | Record<string, any> {
    let result = {
      thousandSeparated: true,
      mantissa: 2,
    };

    switch (currency) {
      case 'CRYPTOSIDE' as CurrencyCode:
        result = {
          thousandSeparated: true,
          mantissa: 2, // 8
        };
        break;
      case CurrencyCode.BTC:
        result = {
          thousandSeparated: true,
          mantissa: 8,
        };
        break;
      case CurrencyCode.RUR:
      case CurrencyCode.USD:
      case CurrencyCode.EUR:
      case CurrencyCode.INR:
        result = {
          thousandSeparated: true,
          mantissa: 2, // 8
        };
        break;
      case CurrencyCode.ETH:
        result = {
          thousandSeparated: true,
          mantissa: 8,
        };
        break;
      case CurrencyCode.USDTt:
      case CurrencyCode.USDT:
        result = {
          thousandSeparated: true,
          mantissa: 6,
        };
        break;
    }

    return result;
  }

  format(amount: string|number, currency = CurrencyCode.USD): string {
    return numbro(amount).format(this.getNumeralFormat(currency))
      .replace(/([.,]\d{2,}?)[0]+$/, '$1');
  }
}
