
export enum RemoteRequestStatesEnum {
  Ready,
  Loading,
  Loaded,
  LoadingError
}

export class RemoteRequestState {
  state: RemoteRequestStatesEnum = RemoteRequestStatesEnum.Ready

  get isLoading() {
    return this.state === RemoteRequestStatesEnum.Loading
  }

  get isLoaded() {
    return this.state === RemoteRequestStatesEnum.Loaded
  }

  get isError() {
    return this.state === RemoteRequestStatesEnum.LoadingError
  }

  get isReady() {
    return this.state === RemoteRequestStatesEnum.Ready
  }

  reset() {
    this.state = RemoteRequestStatesEnum.Ready
  }

  loadStart() {
    this.state = RemoteRequestStatesEnum.Loading
  }

  loadEnd() {
    this.state = RemoteRequestStatesEnum.Loaded
  }

  loadError() {
    this.state = RemoteRequestStatesEnum.LoadingError
  }
}
