import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import MerchantLayout from '@/views/MerchantLayout.vue'
import TransferPage from '@/views/TransferPage.vue'
import RedirectPage from "@/views/RedirectPage.vue";
import TxStatusPage from "@/views/TxStatusPage.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/redirect',
    name: 'Redirect',
    component: RedirectPage,
    meta: {requiresAuth: true},
    props: route => ({ url: route.query.url, sign: route.query.sign })
  },
  {
    path: '/',
    name: 'Home',
    component: MerchantLayout,
    props: true,
    redirect: '/transfer',

    children: [
      {
        path: 'transfer',
        name: 'Ru Cards to USDT transfer',
        component: TransferPage,
        meta: {requiresAuth: true},
      },

      {
        path: 'tx/:o',
        name: 'Ru Cards to USDT transfer',
        component: TxStatusPage,
        meta: {requiresAuth: true},
        props: route => ({ order: route.params.o })
      },

    ],

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
