import Vue from 'vue'
import BasicFormModel from './BasicFormModel'
import ModelErrors from './ModelErrors'
import moment from "moment"

export enum ModelEvents {
    modelLoadingStart = 'model-loading-start',
    modelLoaded = 'model-loaded',
    modelLoadingFail = 'model-loading-fail',
    sourceAccountUpdate = 'source-account-update',
    documentCreated = 'document-created',
    documentVerified = 'document-verified',
    modelSubmitStart = 'model-submit-start',
    modelInvalidated = 'model-invalidated'
}

export interface WebFormModelInterface {
    errors: ModelErrors
    hints: any
    labels: any
    values: any
    options: any
    required: any

    getLabel(attribute: string): string
}

export interface WebFormModelOptions extends Record<string, any> {

}

export default class WebFormModel extends BasicFormModel implements WebFormModelInterface {
    errors: ModelErrors = new ModelErrors()

    hints: any = {}

    labels: any = {}

    values: any = {}

    protected defaultValues: any = {}

    options: WebFormModelOptions = {}

    required: any = []

    constructor(model?: any) {
      super()
      if (model) this._setData(model)
    }

    public _setData(model: any): void {
      this.errors.setErrors(model.errors)
      this.hints = { ...this.hints, ...model.hints }
      this.labels = { ...this.labels, ...model.labels }
      this.values = { ...this.values, ...model.values }
      this.defaultValues = { ...this.defaultValues, ...model.values }
      this.required = model.required.slice()
      this.options = { ...this.options, ...model.options }
    }

    resetValues(): void {
      this.values = Object.assign(this.values, this.defaultValues)
    }

    setValues(values: any): void {
      this.values = Object.assign(this.values, values)
    }

    hasErrors(attribute?: string): boolean {
      return this.errors.has(attribute)
    }

    getError(attribute: string): string {
      return this.errors.has(attribute) ? this.errors.getMessage(attribute) : ''
    }

    getHint(attribute: string): string {
      return (attribute in this.hints) ? this.hints[attribute] : ''
    }

    getLabel(attribute: string): string {
      return (attribute in this.labels) ? this.labels[attribute] : attribute
    }

    getValue(attribute: string): any {
      return (attribute in this.values) ? this.values[attribute] : null
    }

    setValue(attribute: string, value: any): any {
      Vue.set(this.values, attribute, value)
    }

    isRequiredAttribute(attribute: string): boolean {
      return this.required.indexOf(attribute) > -1
    }

    checkAxiosError(axiosError) {
      if (axiosError && (typeof axiosError === 'object') &&
            axiosError.response && axiosError.response.status) {
        if (axiosError.response.status === 422) {
          this.errors.setErrors(axiosError.response.data.model.errors)
        } else {
          this.errors.set('http', `Response Status ${axiosError.response.status}`)
        }
      }
    }

    static parseAxiosWebapiModelErrors(axiosError): ModelErrors {
      const result = new ModelErrors()
      if (axiosError && (typeof axiosError === 'object') &&
        axiosError.response && axiosError.response.status) {
        if (axiosError.response.status === 422) {
          result.setErrors(axiosError.response.data.model.errors)
        } else {
          result.set('http', `Response Status ${axiosError.response.status}`)
        }
      }
      return result
    }
}

export class BackofficeWebFormModel extends WebFormModel {
  get periodOptions() {
    return [
      {
        value: 0,
        custom: false,
        begin: moment().unix(),
        end: moment().unix(),
        option: 'Сегодня',
      },
      {
        value: 1,
        custom: false,
        begin: moment().startOf('month').unix(),
        end: moment().unix(),
        option: 'Текущий месяц',
      },
      {
        value: 2,
        custom: false,
        begin: moment().subtract(3, 'month').unix(),
        end: moment().unix(),
        option: '3 месяца',
      },
      {
        value: 3,
        custom: false,
        begin: moment().subtract(6, 'month').unix(),
        end: moment().unix(),
        option: '6 месяцев',
      },
      {
        value: 4,
        custom: false,
        begin: moment().startOf('year').unix(),
        end: moment().unix(),
        option: 'Этот год',
      },
      {
        value: 5,
        custom: false,
        begin: moment().subtract(1, 'year').startOf('year').unix(),
        end: moment().subtract(1, 'year').endOf('year').unix(),
        option: 'Прошлый год',
      },
    ]
  }
}
