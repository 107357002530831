import axios from "axios";
import {RemoteRequestState} from "@/models/core/remote/remote";

export enum CurrencyCode {
  RUR = 'RUR',
  USD = 'USD',
  EUR = 'EUR',
  BTC = 'BTC',
  ETH = 'ETH',
  TRX = 'TRX',
  USDT = 'USDT',
  USDTt = 'USDTt',
  INR = 'INR',
  BO_USDT_ERC = 'USDT-ERC',
  BO_USDT_TRC = 'USDT-TRC',
  SIMPLE_USDT_ERC = 'USDT-ERC',
  SIMPLE_USDT_TRC = 'USDT-TRC',
  UNKNOWN = 'UNKNOWN'
}

export class SimpleAccount {
  currency: CurrencyCode
}

export class SimpleCryptoAddress {
  currency: CurrencyCode
}

export interface CurrencyGrouppedDataItemInterface extends Record<string, any> {
  account: SimpleAccount
  address: SimpleCryptoAddress
}

export interface CurrencyGrouppedDataInterface extends Record<string | CurrencyCode, CurrencyGrouppedDataItemInterface> {

}

export class FinancePageDetailsModel {

  loadingStatus = new RemoteRequestState();

  reloadAccounts() {
    this.loadingStatus.loadStart();
    return axios.post('/webapi/simpled/financepage', {
      call: 'loadFinances'
    }).then(result => {
      let {accounts, addresses} = result.data.model

      accounts = accounts.map(account => Object.assign(new SimpleAccount(), account));
      addresses = addresses.map(address => Object.assign(new SimpleCryptoAddress(), address));

      return {accounts, addresses}
    }).then(data => {
      this.loadingStatus.loadEnd();
      return data;
    }).catch(e => {
      this.loadingStatus.loadError();
      throw e;
    })
  }
}
