
import Vue from 'vue'
import Component from 'vue-class-component'
//  import {ApplicationLocale} from '../../../../webapp/src/models/ApplicationLocale'
import {ApplicationLocale} from 'webapp/src/models/ApplicationLocale'

// tsconfig: noImplicitAny: false
// import Dropdown from 'uiv/dist/Dropdown'

@Component({
  name: 'HeaderLangSwitcher',
  components: {
    // 'uiv-dropdown': Dropdown
  }
})
export default class HeaderLangSwitcher extends Vue {
  changeLocale(locale: string) {
    this.$i18n.locale = locale
    ApplicationLocale.getInstance().locale = locale
    localStorage.setItem('lang', locale)
  }

  get currentLocale(): string {
    return this.$i18n.locale
  }
}
