import { render, staticRenderFns } from "./RedirectPage.vue?vue&type=template&id=7aec06f4&scoped=true&lang=pug&"
import script from "./RedirectPage.vue?vue&type=script&lang=ts&"
export * from "./RedirectPage.vue?vue&type=script&lang=ts&"
import style0 from "./RedirectPage.vue?vue&type=style&index=0&id=7aec06f4&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aec06f4",
  null
  
)

/* custom blocks */
import block0 from "./RedirectPage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports