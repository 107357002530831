
import Vue from "vue";
import HeaderLangSwitcher from "@/views/layout/HeaderLangSwitcher.vue";
import {CheckRedirectUrlSignModel} from "@/models/transfer";
// import {Component, Prop} from "vue-property-decorator";
//
// @Component({
//   name: "RedirectPage",
// })

const DEFAULT_TIMEOUT = 3

export default Vue.extend({
  // @Prop({default: null})
  // url: string | null

  name: "RedirectPage",

  components: {
    HeaderLangSwitcher
  },

  data() {
    return {
      timer: null,
      counter: DEFAULT_TIMEOUT,
      model: new CheckRedirectUrlSignModel()
    }
  },

  mounted() {
    if (!this.url || !this.sign || (String(this.url).indexOf('https://oplata.qiwi.com') !== 0)) {
      this.$router.push('/')
      return
    }

    this.model.validateUrlSign(this.url, this.sign, this.$i18n.locale)
      .then(() => {
        this.startTimer()
      }).catch(() => {
        this.$router.push('/')
      })
  },

  props: {
    url: String,
    sign: String,
    timeout: {default: DEFAULT_TIMEOUT, type: Number}
  },

  computed: {
    // metas: function () {
    //   return [
    //     {
    //       'http-equiv': "refresh",
    //       content: this.timeout + "; URL=" + this.url // (this.$route.query.url ? this.url : '/')
    //     }
    //   ];
    // },
  },

  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Proceed to payment',
      // all titles will be injected into this template
      // titleTemplate: '%s | My Awesome Webapp',
      meta: [
        // {
        //   httpEquiv: "refresh",
        //   'http-equiv': "refresh",
        //   content: this.timeout + "; URL=" + (this as any).$route.query.url // (this.$route.query.url ? this.url : '/')
        // }
      ]
    }
  },

  methods: {
    startTimer() {
      this.counter = (this.timeout ? this.timeout : DEFAULT_TIMEOUT) + 2
      this.timer = setInterval(() => {
        this.counter--
        if (this.counter == 0) {
          if (this.timer) {
            clearInterval(this.timer as any)
          }
          window.location.href = this.url
        }
      }, 1000) as any
    }
  }

})
