
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {TxInfo, TxStatusModel} from "@/models/status";
import HeaderLangSwitcher from "@/views/layout/HeaderLangSwitcher.vue";
import {MoneyFormatFilter} from "@/models/filters/money";

import {BIconBoxArrowUp} from "bootstrap-vue";
import {RemoteRequestState} from "@/models/core/remote/remote";


@Component({
  name: "TxStatusPage",
  components: {HeaderLangSwitcher, BIconBoxArrowUp},
  filters: {
    money: MoneyFormatFilter
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Payment status',
      // all titles will be injected into this template
      // titleTemplate: '%s | My Awesome Webapp',
      meta: [
        // {
        //   httpEquiv: "refresh",
        //   'http-equiv': "refresh",
        //   content: this.timeout + "; URL=" + (this as any).$route.query.url // (this.$route.query.url ? this.url : '/')
        // }
      ]
    }
  }
})
export default class TxStatusPage extends Vue {
  // @Prop({default: null})
  // merchant: number | string

  @Prop({default: null})
  order: number | string

  model = new TxStatusModel()

  txInfo: TxInfo | null = null

  interval: any = null

  statusOfUpdate = new RemoteRequestState();

  get isLoading() {
    return !this.txInfo
  }

  get updatingStatus() {
    return this.statusOfUpdate.isLoading
  }

  mounted() {
    this.updateStatus()
  }

  tronscanAddressUrl(address: string) {
    return 'https://tronscan.org/#/address/' + encodeURI(address)
  }

  tronscanTransactionUrl(tx: string) {
    return 'https://tronscan.org/#/transaction/' + encodeURI(tx)
  }

  updateStatus() {
    this.statusOfUpdate.loadStart()
    this.model.loadStatus(null, this.order)
      .then(txInfo => {
        this.txInfo = txInfo
        this.statusOfUpdate.loadEnd()
        if (!txInfo.paid || !txInfo.tx) {
          this.interval = setTimeout(() => {
            this.updateStatus()
          }, txInfo.paid ? 25000 : 10000);
        }
        return txInfo
      })
      .catch(err => {
        this.statusOfUpdate.loadError()
        // if (err && (typeof err === 'object') &&
        //   err.response && err.response.status) {
        //   if (err.response.status === 404) {
        //
        //   }
        // }
        this.$router.push('/')
        throw err
      })
  }

}
