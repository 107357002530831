
import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {TransferModel} from "@/models/transfer";
import HeaderLangSwitcher from "@/views/layout/HeaderLangSwitcher.vue";
import {mask} from 'vue-the-mask'
import {MoneyFormatFilter} from "@/models/filters/money";
import {CurrencyCode} from "@/models/finance";
import {BFormInput} from "bootstrap-vue";

@Component({
  name: 'TransferPage',
  components: {HeaderLangSwitcher},
  directives: {mask},
  filters: {
    money: MoneyFormatFilter,
  }
})
export default class TransferPage extends Vue {

  model = new TransferModel()
  redirection = false
  redirectionUrl: string | null = null
  txStatusUrl: string | null = null

  addressReadonly = false

  mounted() {
    const {address, amount, email, pan} = this.$route.query
    this.model.values.address = String('' + (address ?? '')).trim()
    this.model.values.email = String('' + (email ?? '')).trim()
    this.model.values.pan = String('' + (pan ?? '')).trim()
    this.model.values.amount = (this.$refs.inputAmount as BFormInput).formatValue(String('' + (amount ?? '')).trim());

    this.addressReadonly = this.model.values.address.length > 10

    this.touchEstimateUpdate()
    // this.model.values.amount = (this.$refs.inputAmount as BFormInput).formatValue(this.model.values.amount);
    // this.model.applyMock();
  }

  get usdtEstimateInputState() {
    if (!this.model.errors.has('estimate')) {
      return null;
    }

    if (this.model.rateDirty || !this.showUsdtBlock) {
      return null;
    }

    if (!this.model.rateDirty && this.model.rateUpdateStatus.isError) {
      return false;
    }

    if (!this.model.rateDirty && this.model.rateUpdateStatus.isError) {
      return false;
    }

    if (!this.model.rateDirty && this.model.rateCurrent) {
      return true;
    }

    return null;
  }

  get isSubmitting() {
    return this.model.submitStatus.isLoading || this.redirection
  }

  get isDisabledForm() {
    return this.model.submitStatus.isLoading || this.redirection
  }

  get showUsdtBlock() {
    return this.isValidNumberAmount;
    // && (this.model.rateDirty
    // || this.model.rateCurrent)
  }

  get usdtEstimateString() {
    if (!this.model.rateDirty && this.model.rateUpdateStatus.isError) {
      return this.$i18n.t('hints.calculating_error')
    }
    if (this.model.rateDirty || !this.model.rateCurrent) {
      return this.$i18n.t('hints.calculating')
    }
    return MoneyFormatFilter((this.model.rateCurrent as any).result.amount, CurrencyCode.USD)
  }

  onAmountUpdate() {
    this.touchEstimateUpdate()
  }

  touchEstimateUpdate() {
    if (this.isValidNumberAmount) {
      this.model.rateTouch(this.model.values.amount)
    }
  }

  get isValidNumberAmount() {
    return this.model.validateNumber(this.model.values.amount)
  }

  submit() {
    // const nWindow = window.open()
    this.model.submit(this.$i18n.locale).then(({url, sign, tx}) => {
      this.redirection = true
      const routeData = this.$router.resolve({path: '/redirect', query: {url: url, sign: sign}})
      // this.txStatusUrl = '/tx/' + tx
      this.$router.push({path: '/tx/' + tx})
      // this.goToPayment(routeData.href)
      this.openInNewTab(routeData.href)
    }).catch(err => {
      this.touchEstimateUpdate()
      throw err
    })
  }

  @Watch('redirectionUrl')
  onRedirection(newVal) {
    if (newVal && (newVal.length > 0)) {
      this.$router.push({path: this.txStatusUrl as string})
      window.open(newVal, '_blank');
    }

  }

  openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: href,
    }).click();
  }

  goToPayment(url) {
    this.redirectionUrl = url
  }

  inputState(attribute: string) {
    return this.model.errors.hasErrorsSplit(attribute) ? false : null
  }

  panFormatter(pan: string) {
    let value = String(pan)
    value = value.replaceAll(/[^\d\s]/g, ' ')
      .replaceAll(/\s/g, '').substring(0, 18)

    const chunks = value.match(/.{1,4}/g)
    return chunks ? chunks.join(' ') : ''
  }

  amountFormatter(amount: string) {
    let value = String(amount)
    value = value.replaceAll(/[^\d\s,.]/g, '')
      .replaceAll(/,/g, '.')
      .replaceAll(/([.,]\d{1,2})\d*$/g, '$1')

    const chunks = value.split('.')
    if (chunks.length > 2) {
      const cents = chunks.pop()
      value = chunks.join('') + '.' + cents
    }

    // value = value.replaceAll(/[.]\d*$/g, '.00')
    //
    // if ((value.length > 0) && value.indexOf('.') < 0) {
    //   value += '.00'
    // }


    return value
  }
}
