import WebFormModel from '@/models/core/form/WebFormModel'
import {CurrencyCode} from "@/models/finance"
import axios from "axios"
import {RemoteRequestState} from "@/models/core/remote/remote"
import debounce from "debounce"


export class TransferModel extends WebFormModel {

  rateUpdateStatus = new RemoteRequestState()
  rateDirty = false
  rateCurrent = null
  ratePingDebounced = debounce(this.ratePing, 500)

  rateTouch(amount, currency = CurrencyCode.RUR) {
    this.rateDirty = true
    this.rateCurrent = null
    this.errors.clear('estimate')
    return this.ratePingDebounced(amount, currency)
  }

  ratePing(amount, currency = CurrencyCode.RUR) {
    this.rateUpdateStatus.loadStart()
    // this.errors.clear()
    return axios.post('/webapi/merchants/cardtransfer', {
      call: 'checkRate',
      m: {
        currency,
        amount
      }
    }).then(result => {
      if (amount !== this.values.amount) return;
      this.rateDirty = false
      this.rateUpdateStatus.loadEnd()
      this.rateCurrent = result.data
      return result.data
    }).catch(err => {
      if (amount !== this.values.amount) return
      this.rateDirty = false
      this.errors.set('estimate', 'estimate_error')
      // this.checkAxiosError(err)
      this.rateUpdateStatus.loadError()
      throw err
    })
  }

  values = {
    email: '',
    address: '',
    amount: '',
    currency: CurrencyCode.RUR,
    pan: ''
  }

  submitStatus = new RemoteRequestState()

  validateNumber(amount: string) {
    let value: string = String(amount).trim()
    value = value.replaceAll(/[\s]/g, '')
      .replaceAll(/,/g, '.')

    if (value.match(/[^,.\-\d]/)) {
      return false
    }

    if (Number(value) <= 0) {
      return false
    }

    return true
  }


  get mockValues() {
    return {
      email: 'ivan@capitalist.net',
      address: 'T94424876305d5c0af6549ecda02aa6bbb397be3f',
      currency: CurrencyCode.RUR,
      pan: '5319955751101353',
      amount: 3000
    }
  }

  applyMock() {
    this.values = Object.assign(this.values, this.mockValues)
  }

  submit(locale = 'ru') {
    this.errors.clear()
    this.submitStatus.loadStart()
    return axios.post('/webapi/merchants/cardtransfer', {
      call: 'submitOrder',
      lang: locale,
      m: this.values
    }).then(result => {
      this.submitStatus.loadEnd()
      return {
        url: result.data.url,
        sign: result.data.sign,
        tx: result.data.tx,
      }
    })
      .catch(err => {
        this.submitStatus.loadError()
        this.checkAxiosError(err)
        throw err
      })
  }
}

export class CheckRedirectUrlSignModel extends WebFormModel {
  values = {
    url: '',
    sign: ''
  }

  validateUrlSign(url, sign, locale = 'ru') {
    this.errors.clear();
    return axios.post('/webapi/merchants/cardtransfer', {
      call: 'checkRedirectSign',
      lang: locale,
      url: url,
      sign: sign
    }).then(result => result.data.ok)
      .catch(err => {
        this.checkAxiosError(err)
        throw err
      })
  }
}
