
import Vue from "vue"
import {Component} from "vue-property-decorator"

import VueInlineSvg from 'vue-inline-svg'
import HeaderLangSwitcher from "@/views/layout/HeaderLangSwitcher.vue"

@Component({
  name: 'App',
  components: {
    HeaderLangSwitcher,
    VueInlineSvg
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Transfer Application',
    // all titles will be injected into this template
    titleTemplate: '%s | CardTransfer.org'
  },
})
export default class App extends Vue {

  created() {
    const savedLocale = localStorage.getItem('lang')
    this.$i18n.locale = !savedLocale || ['ru', 'en'].indexOf(savedLocale) == -1 ? 'ru' : savedLocale as string
  }

}
